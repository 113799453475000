exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-hirer-js": () => import("./../../../src/pages/features/hirer.js" /* webpackChunkName: "component---src-pages-features-hirer-js" */),
  "component---src-pages-features-uni-js": () => import("./../../../src/pages/features/uni.js" /* webpackChunkName: "component---src-pages-features-uni-js" */),
  "component---src-pages-hirer-js": () => import("./../../../src/pages/hirer.js" /* webpackChunkName: "component---src-pages-hirer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

